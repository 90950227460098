<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4 grey lighten-4">
      <div class="d-flex align-center justify-space-between mb-4">
        <p class="subtitle-1 ma-0">
          Escolha um Modelo abaixo para editar ou excluir.
        </p>
        <v-btn
          small
          depressed
          color="fmq_gray"
          dark
          to="/banco-de-cartas-ic-criar"
          >Novo Modelo IC</v-btn
        >
      </div>
      <ModelosTabela
        :headers="headers"
        :itens="itens"
        :total="total"
        :pageText="pageText"
        :pageNumber="page"
        :loading="loading"
        @handleFilter="filter"
        @duplicar="duplicarModal"
        @openPDF="abrirPDF"
        @excluir="excluirModal"
        visualizarRoute="BancoDeCartasICVisualizar"
        editarRoute="BancoDeCartasICEditar"
      >
        <template v-slot:[`produtos`]>
          <v-autocomplete
            v-model="formData.produtosId"
            :items="listProdutos"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Busque pelo produto"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`categorias`]>
          <v-autocomplete
            v-model="formData.categoriasId"
            :items="listCategorias"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Busque pelo produto"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:titulo>
          <v-text-field
            v-model="formData.titulo"
            placeholder="Busque pelo titulo"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              page = 1;
              buscar(formData);
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:tags>
          <v-autocomplete
            v-model="formData.tagsId"
            :items="listaDeTags"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Busque pela tag"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:usoApp>
          <v-autocomplete
            v-model="formData.usoApp"
            :items="disponivelApp"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="valor"
            placeholder="Selecione"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`autor.nome`]>
          <v-text-field
            solo-inverted
            flat
            dense
            :hide-details="true"
            disabled
            readonly
          >
          </v-text-field>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-start">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </ModelosTabela>
      <AlertConfirmation
        :icon="icon"
        :colorIcon="colorIcon"
        :dialog="dialog"
        :dialogMessage="dialogMessage"
        @close="dialog = false"
        @accept="excluir"
      />
      <AlertConfirmation
        :icon="icon"
        :colorIcon="colorIcon"
        :dialog="dialogDuplicar"
        :dialogMessage="dialogMessage"
        @close="dialog = false"
        @accept="duplicar"
      />
      <AlertSuccess
        :dialog="success"
        :dialogMessage="messageSuccessful"
        @close="
          buscar(formData);
          success = false;
          clear();
        "
      />
      <AlertError :alertError="error" :messageError="messageError" />
    </div>
  </div>
</template>

<script>
import ModelosTabela from "@/components/modelos/ModelosTabela.vue";
import {
  buscarModelos,
  excluirModelo,
  duplicarModelo,
  visualizarPDFModelo,
} from "@/services/modelos.js";
import { listarProdutos } from "@/services/produtos.js";
import { listarTags } from "@/services/tags.js";
import { listarCategoria } from "@/services/categorias.js";
export default {
  name: "BancoDeCartasIC",
  components: { ModelosTabela },
  data: () => ({
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: 15,
      produtosId: null,
      categoriasId: null,
      titulo: null,
      tagsId: null,
      usoApp: null,
      autorId: null,
      mesasId: 1,
    },
    headers: [
      { text: "Produtos", value: "produtos", sortable: false },
      { text: "Categorias", value: "categorias", sortable: false },
      { text: "Título", value: "titulo" },
      { text: "Tags", value: "tags", sortable: false },
      { text: "Disponível no app", value: "usoApp", sortable: false },
      { text: "Última alteração", value: "autor.nome", sortable: false },
      { text: "Ações", value: "id", sortable: false },
    ],
    page: 1,
    itens: [],
    total: null,
    pageText: null,
    loading: false,
    breadcrumbs: [
      {
        text: "Banco de Modelos",
        disabled: true,
        to: "",
      },
    ],
    dialog: false,
    dialogDuplicar: false,
    dialogMessage: null,
    icon: null,
    colorIcon: null,
    itemId: null,
    error: false,
    success: false,
    messageSuccessful: null,
    messageError: null,
    listProdutos: [],
    listaDeTags: [],
    listCategorias: [],
    disponivelApp: [
      { nome: "Sim", valor: "1" },
      { nome: "Não", valor: "0" },
    ],
    excludeTypePermission: ["Colaborador", "Administrador de mesa"],
  }),
  beforeCreate() {
    if (
      this.$store.state.user.data.tipo === "Representante" ||
      (this.$store.state.user.data.tipo === "Colaborador" &&
        this.$store.state.user.data.funcionario.mesaId === 1)
    ) {
      this.$router.push({ path: "/treinamento" });
    }
  },
  created() {
    if (
      this.$store.state.user.data.funcionario.mesaId === 2 &&
      this.excludeTypePermission.includes(this.roleUser)
    ) {
      this.$router.push({ path: "/" });
    }
    if (this.$store.state.cartasSearch.length) {
      this.formData = { ...this.$store.state.cartasSearch[0] };
    }
    this.buscar(this.formData);
    this.listProducts();
    this.listTags();
    this.listCategories();
  },
  methods: {
    async buscar(payload) {
      this.itens = [];
      this.$store.dispatch("setCartasSearch", payload);
      try {
        this.loading = true;
        const resp = await buscarModelos(payload, this.page);
        this.itens = resp.data.data;
        this.total = resp.data.total;
        this.pageText = `${resp.data.from}-${resp.data.to} de ${resp.data.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.$store.dispatch("setCartasSearch", {});
      this.formData.produtosId = null;
      this.formData.autor = null;
      this.formData.dataPublicacao = null;
      this.formData.titulo = null;
      this.formData.tagsId = null;
      this.formData.usoApp = null;
      this.formData.categoriasId = null;
      this.page = 1;
      this.buscar(this.formData);
    },
    search() {
      this.page = 1;
      this.buscar(this.formData);
    },
    filter(event) {
      this.$store.dispatch("setCartasSearch", this.formData);
      this.formData.orderBy = event.orderBy;
      this.formData.orderSorted = event.orderSorted;
      this.formData.perPage = event.perPage;
      this.page = event.page;
      this.buscar(this.formData);
    },
    async excluir() {
      this.error = false;
      this.sucess = false;
      try {
        await excluirModelo(this.itemId).then(() => {
          this.dialog = false;
          this.success = true;
          this.messageSuccessful = "Modelo excluido com sucesso!";
          this.page = 1;
        });
      } catch (e) {
        this.dialog = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async abrirPDF(id) {
      this.error = false;
      this.sucess = false;
      try {
        await visualizarPDFModelo(id).then((resp) => {
          window.open(resp.data.link, "_blank");
        });
      } catch (e) {
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async duplicar() {
      this.error = false;
      this.sucess = false;
      try {
        await duplicarModelo(this.itemId).then(() => {
          this.dialogDuplicar = false;
          this.success = true;
          this.messageSuccessful = "Modelo duplicado com sucesso!";
          this.page = 1;
        });
      } catch (e) {
        this.dialogDuplicar = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    excluirModal(item) {
      this.icon = "mdi-alert-circle-outline";
      this.colorIcon = "red";
      this.dialogMessage = `Tem certeza que deseja excluir ${item.titulo}?`;
      this.dialog = true;
      this.itemId = item.id;
    },
    duplicarModal(item) {
      this.icon = "mdi mdi-plus-circle-outline";
      this.colorIcon = "blue";
      this.dialogMessage = `Tem certeza que deseja duplicar ${item.titulo}?`;
      this.dialogDuplicar = true;
      this.itemId = item.id;
    },
    async listProducts() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
    async listTags() {
      const resp = await listarTags();
      this.listaDeTags = resp.data;
    },
    async listCategories() {
      const resp = await listarCategoria();
      this.listCategorias = resp.data;
    },
  },
  computed: {
    roleUser() {
      return this.$store.state.user.data.tipo;
    },
  },
};
</script>
